section {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: teal;
  height: 100vh;
  padding: 50px 0;
}

img {
  border-radius: 20%;
  box-shadow: 0 0 10px rgba(0, 0, 0, .25);
}

form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin: 20px 0;
  width: fit-content;
}

form > input {
  width: 250px;
  cursor: pointer;
  background-color: black;
  border-width: 0;
  color: white;
  font-weight: bold;
  padding: 10px;
  border-radius: 5px;
}

form > div {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  min-width: 250px;
  gap: 10px;
}

form > div > label {
  display: flex;
  flex-direction: column;
  gap: 5px;
  flex: 1;
  color: white;
  font-weight: bold;
}

form > div > label > input {
  flex: 1;
}